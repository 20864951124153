import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Mh3 = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #B3054F, #C70256, #D4005A, #C70256, #B3054F )",
        icons: "#fff",
        navClass: "mh3",
      }}
      seo={{
        title: "Historical Cities 3.0",
        headerTitle: "mh30",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/mh30_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/mh-3-0/",
      }}
    >
      <PortfolioHeader name="mh30" height="189" />
      <section className="container-fluid mh30_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Historical Cities 3.0</h1>
              <ul>
                <li>Logo</li>
                <li>Branding</li>
                <li>Materiały promocyjne</li>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Miasta Historyczne 3.0 to międzynarodowa konferencja
                organizowana przez Urząd Miasta Krakowa. Przygotowaliśmy pełną
                identyfikację wizualną projektu oraz materiały informacyjne i
                promocyjne na pierwszą edycję tej międzynarodowej konferencji.
                Efektem naszej pracy była m.in.; pełna identyfikacja wraz z
                brandbookiem i materiałami POS. Byliśmy również odpowiedzialni
                za opracowanie modelu funkcjonalnego, projekt graficzny i
                wdrożenie strony internetowej konferencji.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid mh30_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/mh30_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/mh30_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Magenta CMYK</p>
                    <h5>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 25 : 0}
                      />
                      <CountUp start={0} duration={3} end={isVisible ? 8 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Gold CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 22 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 28 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 62 : 0}
                      />
                      <CountUp start={0} duration={3} end={isVisible ? 1 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 216 : 0}
                      />
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 91 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 203 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 176 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 118 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>d8005b</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>cbb076</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mh30_section_4">
        <h4>Materiały promocyjne</h4>
        <img
          src={require("../../assets/img/portfolio/mh30_main_4.png")}
          alt=""
        />
      </section>
      <section className="mh30_section_5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="inner">
              <h4>Strona internetowa</h4>
              <p>
                Opracowaliśmy makietę serwisu oraz jego projekt graficzny (w
                oparciu o zaprojektowaną wcześniej identyfikację wizualną
                konferencji). Głównym celem wdrożenia było przedstawienie agendy
                konferencji oraz występujących na niej prelegentów.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/mh30_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mh30_section_6">
        <img
          className="img-fluid"
          src={require("../../assets/img/portfolio/mh30_main_6.png")}
          alt=""
        />
      </section>
      <section className="mh30_section_7">
        <img
          className="img-fluid"
          src={require("../../assets/img/portfolio/mh30_main_7.png")}
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Mh3;
